import {withAuthenticationRequired} from '@auth0/auth0-react';
import {Form, Formik} from 'formik';
import {FC, useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';
import {IOrganization, useAdminOrganization, useUpdateAdminOrganization} from '../../API';
import {useCreateTrialSubscription} from '../../API/Mutations/useCreateTrialSubscription';
import {Body1, FlexRow, FullPageLoader, StandardButton, Subhead1, TextInput, ToggleButtons} from '../../Components';
import {BreadCrumbs} from '../../Components/Molecules/BreadCrumbs';
import {OrganizationHeader} from '../../Components/Molecules/OrganizationHeader';
import {useCartOrganization} from '../../Hooks/useCartOrganization';
import {useRoles} from '../../Hooks/useRoles';
import {requireAdmin} from '../../Managers/AuthUtils';
import {routePaths} from '../../constants/routePaths';
import {Header} from '../Map/Header';
import {CardContainer} from '../Onboarding/Layout';
import {OrgChangeModal} from '../OrgAdmin/OrgChangeModal';
import {AdminMenu} from './AdminMenu';
import {FeaturesTab} from './FeaturesTab';
import {AdminPage, AdminPageWrapper} from './Layout';
import {BillingTab} from './Tabs/BillingTab';
import {LocationsTab} from './Tabs/LocationsTab';
import {MembersTab} from './Tabs/MembersTab';
import {OrganizationTab} from './Tabs/OrganizationTab';

const getMinMaxDates = () => {
  // the min for the date picker is 1 month from now
  const minDate = new Date();
  minDate.setMonth(minDate.getMonth() + 1);
  // format date string
  const formattedMinDate = minDate.toISOString().split('T')[0];

  // the max for the date picker is 1 year from now
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 2);
  // format date string
  const formattedMaxDate = maxDate.toISOString().split('T')[0];

  return {formattedMinDate, formattedMaxDate};
};
const TOGGLE_LABELS = ['General Information', 'Members', 'Locations', 'Features', 'Billing'];

const LITERALS = {
  missingSubscriptionType: 'This organization does not have a subscription type. Please select a subscription type to continue.',
  missingAdmin: 'This organization does not have an admin with a subscription. Please select or add an organization admin to continue.',
  cancelToChange: 'Changing your org type requires you to cancel your subscription. View your subscription payment details to get started.',
  adminCanChange:
    "You can change this organization's subscription type, but it won't take effect until there's a change in the list of users or locations. Click the button below to change the subscription type.",
};

const validationSchema = yup.object({
  periodEnd: yup.date().required('Please select a date to end the current subscription period.'),
});

const PeriodEndForm: FC<{organization: IOrganization}> = ({organization}) => {
  const createTrialSubscription = useCreateTrialSubscription();

  const handleSubmit = async (values: any) => {
    const unixTime = new Date(values?.periodEnd).getTime() / 1000;

    if (!organization?.primary_email) {
      toast.error('The primary email address for the organization has not been set.');
      return;
    }

    const trialResult = await createTrialSubscription.mutateAsync({
      organization: {
        ...organization,
        subscription_type: organization?.subscription_type || 'standard',
      } as IOrganization,
      periodEnd: unixTime,
    });

    if (trialResult?.data?.createTrialSubscription?.id) {
      toast.success('Trial subscription successfully created.');
    }

    return trialResult;
  };

  const hasEmail = organization?.primary_email;

  return (
    <CardContainer style={{margin: 0}}>
      <Formik initialValues={{periodEnd: getMinMaxDates().formattedMaxDate}} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({isSubmitting, values, errors}) => (
          <Form>
            <Subhead1 style={{color: 'red'}}>This organization does not have a subscription.</Subhead1>
            {!hasEmail && <Subhead1 style={{color: 'red'}}>The primary email address for the organization has not been set.</Subhead1>}
            <FlexRow style={{gap: 16}}>
              <Body1 style={{flex: 1}}>
                Select a date to end the current subscription period. This will prevent the subscription from renewing and will allow you to
                change the subscription type.
              </Body1>
              <div style={{gap: 8}}>
                <Body1 style={{margin: 0}}>Subscription End Date</Body1>
                <div style={{flex: 1}} />
                <TextInput name="periodEnd" type="date" min={getMinMaxDates().formattedMinDate} max={getMinMaxDates().formattedMaxDate} />
                <StandardButton type="submit" style={{padding: '8px 16px', flex: 1}} disabled={!hasEmail}>
                  Save
                </StandardButton>
              </div>
            </FlexRow>
          </Form>
        )}
      </Formik>
    </CardContainer>
  );
};

const EditOrganizationComponent: FC = () => {
  const [activeSwitch, setActiveSwitch] = useState(0);
  const [showOrgChangeModal, setShowOrgChangeModal] = useState(false);
  const [orgForUpdate, setOrgForUpdate] = useState<IOrganization | null>(null);

  const {updateCartOrganization} = useCartOrganization();

  const {isAdmin} = useRoles();

  const navigate = useNavigate();
  const params = useParams();

  const updateAdminOrganization = useUpdateAdminOrganization();

  const {data: organization, isFetching} = useAdminOrganization(params?.organizationId as string);

  useEffect(() => {
    organization && updateCartOrganization(organization);
  }, [organization]);

  const isMissingType = !organization?.subscription_type;

  const isMissingSubscription = !organization?.subscription;

  const handleUpdateOrg = async (values: any, hideConfirmation = false) => {
    if (!orgForUpdate && organization?.subscription_type && organization.subscription_type !== values.subscription_type) {
      setOrgForUpdate(values);
      setShowOrgChangeModal(true);
    } else {
      setShowOrgChangeModal(false);
      setOrgForUpdate(null);
      await updateAdminOrganization
        .mutateAsync(values)
        .then((r) => {
          !hideConfirmation && toast.success('Organization has been successfully updated!');
        })
        .catch((e) => {
          console.log('save error', e);
          toast.error('There was a problem with the update!');
        });
    }
  };

  // const handleTrialSetup = async (values: any) => {
  //   console.log('handleTrialSetup', values);

  //   return;
  //   const trialResult = await createTrialSubscription.mutateAsync({organization: values});
  //   if (trialResult?.data?.createTrialSubscription?.id) {
  //     toast.success('Trial subscription successfully created.');
  //     navigate(routePaths.adminOrganizations);
  //   }
  // };

  const handleToggleSelect = (index: number) => {
    setActiveSwitch(index);
  };

  return (
    <AdminPageWrapper>
      {(isFetching || updateAdminOrganization.isLoading) && <FullPageLoader />}

      <AdminMenu />

      <Header />

      <BreadCrumbs name={organization?.name} />

      <AdminPage style={{maxWidth: '100%'}}>
        <OrganizationHeader organization={organization as IOrganization} />

        <div style={{maxWidth: 543, minWidth: 543, flex: 0, display: 'flex'}}>
          <ToggleButtons
            onClick={handleToggleSelect}
            buttons={TOGGLE_LABELS}
            selected={activeSwitch || 0}
            disabled={isMissingSubscription}
          />
        </div>

        {/* {!isFetching && isMissingType && (
          <div style={{flex: 0}}>
            <MissingSubscription isMissingType={isMissingType} />
          </div>
        )} */}

        {!isFetching && isMissingSubscription && <PeriodEndForm organization={organization as IOrganization} />}

        <div style={{flex: 0}}>
          {activeSwitch === 0 && <OrganizationTab organization={organization as IOrganization} onUpdate={handleUpdateOrg} />}

          {activeSwitch === 1 && <MembersTab organization={organization as IOrganization} />}

          {activeSwitch === 2 && <LocationsTab organization={organization as IOrganization} />}

          {activeSwitch === 3 && <FeaturesTab organization={organization as IOrganization} onUpdate={handleUpdateOrg} />}

          {activeSwitch === 4 && <BillingTab organization={organization as IOrganization} />}
        </div>
      </AdminPage>

      <OrgChangeModal
        isOpen={showOrgChangeModal}
        onClick1={() => setShowOrgChangeModal(false)}
        onClick2={() => (isAdmin ? handleUpdateOrg(orgForUpdate) : navigate(routePaths.adminOrganizations))}
        message={isAdmin ? LITERALS.adminCanChange : LITERALS.cancelToChange}
      />
    </AdminPageWrapper>
  );
};

export const EditOrganization: FC = withAuthenticationRequired(EditOrganizationComponent, {claimCheck: requireAdmin});
