import {FC} from 'react';
import {IOrganization} from '../../../API';
import {OrganizationMembersPanels} from '../../Onboarding/OrganizationMembersPanels';

export const MembersTab: FC<{organization: IOrganization; isForTrial?: boolean; onSubmit?: (a: any) => Promise<any>}> = ({
  isForTrial,
  onSubmit,
  organization = {} as IOrganization,
}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', gap: 24, maxWidth: '100%'}}>
      <OrganizationMembersPanels organization={organization} hideTitle updateSubscriptionOnly isForTrial={isForTrial} onSubmit={onSubmit} />
    </div>
  );
};
