import {InformationCircleIcon} from '@heroicons/react/outline';
import {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import {Body3, Body4, FlexRow, H6, HR, IndicatorBar, Subhead1, Subhead2, Subhead3, Theme} from '../../Components';
import {IndigenousBoundaries, PhillyBuildings, RacialComposition, Redlining} from '../../Managers/Datasets';
import {
  IBuildingValue,
  IDatasetConfig,
  IDatasetSubset,
  IDemographicValue,
  IRedliningValue,
  ITribeValue,
} from '../../Managers/Datasets/Types';
import {AllDatasets, Colors2d, RadioSubsetTitlesMap} from '../../Managers/MapManager';
import {formatIncomeStressCurrencyInt, formatPercent} from '../../Managers/MathUtils';
import {useAppSelector} from '../../store';

const KCCounties = {
  '95': 'Jackson',
  '47': 'Clay',
  '165': 'Platte',
};

const KCStateFIP = '29';

const KCCountiesFIPs = Object.keys(KCCounties) as (keyof typeof KCCounties)[];

const joinCSV = (items: any[]) => items.filter((item) => !!item).join(', ');

const getTitle = (key: string, downloadTitle: string) => {
  return RadioSubsetTitlesMap[key] || downloadTitle || '';
};

const getVariantTitles = (subsetVariants: string[], ds: any) => {
  return subsetVariants?.length === 1
    ? ds.subsetVariants?.find((variant: IDatasetSubset) => variant.id === subsetVariants[0])?.title
    : null;
};

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 120px;
  left: 396px;
  width: 440px;
  padding: 28px;
  background: #fff;
  border-radius: 16px;

  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

const BaseData = styled.div`
  flex-direction: row;
`;

const BaseDataEntry = styled.div`
  &:first-child {
    margin-right: 28px;
  }
`;

const Indicators = styled.div`
  margin: 28px 0 12px 0;
`;

const PinSubheading = styled(Subhead3)`
  color: ${(props) => props.theme.Colors.Neutrals._200};
  margin: 16px 0 0 0;
`;

const BoldSpan = styled.span`
  font-weight: 600;
  text-transform: capitalize;
`;

/**
 * The pop-up information window when the user hovers over a tract.
 */
export const RollOver: FC = () => {
  const hoveredPin = useAppSelector((state) => state.map.hoveredPin);
  const hoveredTract = useAppSelector((state) => state.map.hoveredTract);
  const mapData = useAppSelector((state) => state.map.mapData);
  const showBuildingPins = useAppSelector((state) => state.map.showBuildingPins);
  const {dataset1, dataset2} = useAppSelector((state) => state.user);
  const hoveredRow = mapData?.values.find((row) => (row.tractId || row.stateCountyId) === hoveredTract?.GEOID);
  if (!hoveredRow) {
    return <></>;
  }

  const ds1 = AllDatasets.find((ds) => ds.id === dataset1.id);
  const ds2 = AllDatasets.find((ds) => ds.id === dataset2.id);

  // There's a pattern of reuse for the min/max values emerging, we could refactor that to the store...
  const value1 = ds1?.getValue(dataset1, hoveredRow) || 0;
  const value2 = ds2?.getValue(dataset2, hoveredRow) || 0;
  const [min1, max1] = ds1?.getMinMax(dataset1, mapData?.values || []) || [0, 0];
  const [min2, max2] = ds2?.getMinMax(dataset2, mapData?.values || []) || [0, 0];

  // I was a little unclear on the specific math between the designs and the tickets, so I focused on the UI here. We can always tweak
  // the formulae later.

  const renderIndicatorBar = (
    dataset: typeof ds1,
    config: IDatasetConfig,
    min: number,
    max: number,
    value: number | IRedliningValue | IBuildingValue | IDemographicValue | ITribeValue,
    color?: any,
  ) => {
    if (dataset?.id === RacialComposition.id) {
      const v = value as IDemographicValue;
      return (
        <>
          <IndicatorBar formatter={formatPercent} value={v.asian} min={min} max={max} label="Asian" color={Theme.Colors.Oranges._000} />
          <IndicatorBar formatter={formatPercent} value={v.black} min={min} max={max} label="Black" color={Theme.Colors.Yellows._000} />
          <IndicatorBar
            formatter={formatPercent}
            value={v.hispanic}
            min={min}
            max={max}
            label="Latinx/Hispanic"
            color={Theme.Colors.Teals._000}
          />
          <IndicatorBar
            formatter={formatPercent}
            value={v.amIndian}
            min={min}
            max={max}
            label="Native American"
            color={Theme.Colors.Cyans._000}
          />
          <IndicatorBar
            formatter={formatPercent}
            value={v.islander}
            min={min}
            max={max}
            label="Pacific Islander"
            color={Theme.Colors.Blues._000}
          />
          <IndicatorBar formatter={formatPercent} value={v.white} min={min} max={max} label="White" color={Theme.Colors.Purples._000} />
          <IndicatorBar formatter={formatPercent} value={v.other} min={min} max={max} label="Other" color={Theme.Colors.Pinks._000} />
        </>
      );
    }

    if (dataset?.id === Redlining.id) {
      const redliningValue = value as IRedliningValue;
      return (
        <>
          {!!Object.keys(redliningValue)?.length &&
            Object.keys(redliningValue).map((v, index) => {
              return (
                <IndicatorBar
                  key={index}
                  formatter={formatPercent}
                  value={redliningValue[v as keyof IRedliningValue]}
                  min={min}
                  max={max}
                  label={Redlining.subsets?.[index].title || ''}
                  color={Redlining.subsets?.[index].color || Theme.Colors.Oranges._000}
                />
              );
            })}
        </>
      );
    }

    if (dataset?.id === PhillyBuildings.id) {
      const buildingValue = value as IBuildingValue;
      return (
        <>
          {<Subhead3 style={{marginBottom: 14}}>{getVariantTitles(config.subsetVariants, ds1)}</Subhead3>}
          {!!Object.keys(buildingValue)?.length &&
            Object.keys(buildingValue).map((v: any, index: number) => {
              const minMax = dataset?.getMinMax(config, mapData?.values || [], v);
              const subset = dataset?.subsets?.[index];
              const isInverted = subset?.isInverted;

              return (
                <IndicatorBar
                  key={index}
                  formatter={(value) => dataset.formatValue({...config, subsets: [v]}, value)}
                  value={buildingValue[v as keyof IBuildingValue] || 0}
                  min={minMax?.[isInverted ? 1 : 0] || 0}
                  max={minMax?.[isInverted ? 0 : 1] || 0}
                  label={dataset.subsets?.[index]?.title || ''}
                  color={dataset.subsets?.[index]?.color || Theme.Colors.Oranges._000}
                />
              );
            })}
        </>
      );
    }

    if (dataset?.id === IndigenousBoundaries.id) {
      const tribevalue = value as ITribeValue;
      const isTract = !!tribevalue?.tractId; // Tract or County?
      return isTract ? (
        <>
          <Subhead3 style={{margin: 0}}>Federally Recognized Tribes:</Subhead3>
          <Body4 style={{margin: '0 0 10px'}}>{tribevalue?.fedTribes?.length ? tribevalue?.fedTribes : 'None'}</Body4>
          <Subhead3 style={{margin: 0}}>State Recognized Tribes:</Subhead3>
          <Body4 style={{margin: '0 0 10px'}}>{tribevalue?.stateTribes?.length ? tribevalue?.stateTribes : 'None'}</Body4>
          <Subhead3 style={{margin: 0}}>Historical Tribes:</Subhead3>
          <Body4 style={{margin: '0 0 10px'}}>{tribevalue?.histTribes?.length ? tribevalue?.histTribes : 'None'}</Body4>
        </>
      ) : (
        <Subhead3 style={{}}>
          Tracts with Federally Recognized Tribes: {tribevalue?.fedTribes?.length ? tribevalue?.fedTribes : '0'}
        </Subhead3>
      );
    }

    return (
      <IndicatorBar
        formatter={(value) => dataset?.formatValue(config, value) || ''}
        value={value as number}
        min={dataset?.isInverted ? max : min}
        max={dataset?.isInverted ? min : max}
        label={RadioSubsetTitlesMap[config.subsets?.[0]] || dataset?.downloadTitle}
        color={color}
      />
    );
  };

  const getTractIndicatorColor = (tractId: string | undefined, indicator: number) => {
    // const level1 = tractBuckets1?.values[tractId || ''] || 'L';
    // const level2 = tractBuckets2?.values[tractId || ''] || 'L';
    return (indicator === 0 ? Colors2d['H']['L'] : Colors2d['H']['H']) || Theme.Colors.Oranges._000;
  };

  const countyFIP = String(hoveredRow?.countyFIP) as keyof typeof KCCounties;
  const tractCountyName =
    hoveredRow?.stateFIP === +KCStateFIP && KCCountiesFIPs.includes(countyFIP) ? KCCounties[countyFIP] : mapData?.countyData?.countyName;

  // I cheated here and did some inline styling because I'm starting to notice patterns throughout the site where default Typography
  // margins aren't honored. The H4-to-Subhead3 spacing from Figma for this box is 28px. The Subhead3-to-H6 spacing is 4px. I considered
  // removing the margins from Typography but reverted that because then of course the default browser margins take effect (and they're
  // even worse because they can change between different OS/browser/preferences sets.) For now I just forced what we need here, we can
  // come back to this later.
  return (
    <Wrapper>
      <div style={{marginBottom: 20}}>
        <H6 style={{color: Theme.Colors.Oranges._000, marginBottom: 0}}>
          {hoveredRow?.countyName ? (
            <>
              {hoveredRow.countyName}, {hoveredRow.stateName}
            </>
          ) : (
            <>{hoveredRow.tractName}</>
          )}
        </H6>
        {!hoveredRow?.countyName && !!tractCountyName && (
          <Body3
            style={{
              color: Theme.Colors.Neutrals._300,
              margin: 0,
            }}>{`${tractCountyName} County, ${mapData?.countyData?.stateCode}`}</Body3>
        )}
      </div>
      <BaseData>
        <BaseDataEntry>
          <Subhead3 style={{marginBottom: 4}}>Number of Households</Subhead3>
          <H6 style={{marginBottom: 0}}>{(hoveredRow['over200k'] || 0).toLocaleString()}</H6>
        </BaseDataEntry>

        <BaseDataEntry>
          <Subhead3 style={{marginBottom: 4}}>Median Income</Subhead3>
          <H6 style={{marginBottom: 0}}> {formatIncomeStressCurrencyInt(hoveredRow.incomeStress)}</H6>
        </BaseDataEntry>
      </BaseData>
      <Indicators>
        <Subhead2 style={{marginBottom: 14}}>
          {getTitle(dataset1.subsets?.[0], ds1?.downloadTitle || '')}
          {ds2 ? ' and ' + getTitle(dataset2.subsets?.[0], ds2?.downloadTitle || '') : ''}
        </Subhead2>

        {ds1 ? (
          renderIndicatorBar(
            ds1,
            dataset1,
            min1,
            max1,
            value1,
            ds1 && ds2 ? getTractIndicatorColor(hoveredTract?.GEOID, 0) : Theme.Colors.Oranges._000,
          )
        ) : (
          <></>
        )}
        {ds2 ? (
          renderIndicatorBar(
            ds2,
            dataset2,
            min2,
            max2,
            value2,
            ds1 && ds2 ? getTractIndicatorColor(hoveredTract?.GEOID, 1) : Theme.Colors.Oranges._000,
          )
        ) : (
          <></>
        )}
      </Indicators>

      {!!hoveredRow?.disadvComm && (
        <BaseDataEntry>
          <FlexRow style={{width: '100%', flex: 1, alignItems: 'flex-start', gap: 8, marginBottom: 8}}>
            <Subhead2 style={{marginBottom: 0}}>Disadvantaged Community: </Subhead2>

            <Subhead2 style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8, marginBottom: 0}}>
              <BoldSpan style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8, minWidth: 30}}>
                {hoveredRow?.disadvComm}
              </BoldSpan>
              <InformationCircleIcon
                style={{height: 20, width: 20, fontSize: 20, color: Theme.Colors.Oranges._000}}
                data-tip='
          A community or census tract qualifies as “disadvantaged” if it is within the top 10% of economic, environmental/climate, health,
          or housing burdens. It aims to direct federal funding to these communities through initiatives like
          <a target="blank" href="https://www.energy.gov/diversity/justice40-initiative">Justice40.</a>.'
                data-for="rollover-tooltip"
              />
            </Subhead2>
          </FlexRow>
          <Body3 style={{marginBottom: 0, fontStyle: 'italic'}}>
            *This layer was adopted from the federal Climate and Economic Justice Screening Tool (2022) <br />
          </Body3>
        </BaseDataEntry>
      )}

      {showBuildingPins && hoveredPin ? (
        <div style={{borderTop: '1px solid #f3f3f4', marginTop: 16}}>
          <HR />
          <div style={{flexDirection: 'row'}}>
            <img src="/basic-pin.png" width={24} style={{width: 36, height: 39, marginRight: 8}} alt="Marker" />
            <div>
              <Subhead1 style={{marginBottom: 0}}>{hoveredPin.propertyName}</Subhead1>
              <Body3>{joinCSV([hoveredPin.address1, hoveredPin.city, hoveredPin.state, hoveredPin.postalCode])}</Body3>
            </div>
          </div>
          <PinSubheading>Building ID</PinSubheading>
          {hoveredPin.id}
          <PinSubheading>Energy STAR Score</PinSubheading>
          {hoveredPin.energyStarScore || '-'}
          <PinSubheading>Weather Normalized Site EUI (kBtu/sq ft)</PinSubheading>
          {hoveredPin.weatherNormalizedEUI || '-'}
        </div>
      ) : (
        <></>
      )}
      <ReactTooltip
        id="rollover-tooltip"
        type="dark"
        effect="solid"
        wrapper="span"
        className="info-tooltip"
        html={true}
        clickable={true}
        delayHide={1000}
      />
    </Wrapper>
  );
};
