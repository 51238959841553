import {useQuery} from 'react-query';
import {
  getOrganization,
  getOrganizationInvoices,
  getOrganizationProducts,
  getOrganizations,
  getProduct,
  getProducts,
  getUsers,
} from '../Calls/Admin';

export const useAdminUsers = () => useQuery(['ADMIN', 'USERS'], getUsers);

export const useAdminProducts = (enabled = true) => useQuery(['ADMIN', 'PRODUCTS'], getProducts, {enabled: enabled});

export const useAdminProduct = (productId: string) => useQuery(['ADMIN', 'PRODUCT', productId], () => getProduct(productId));

export const useAdminOrganizations = () => useQuery(['ADMIN', 'ORGANIZATIONS'], getOrganizations);

export const useAdminOrganization = (organizationId: string, enabled = true) =>
  useQuery(['ADMIN', 'ORGANIZATION', organizationId], () => getOrganization(organizationId), {enabled: !!organizationId && enabled});

export const useAdminOrganizationInvoices = (organizationId: string) =>
  useQuery(['ADMIN', 'ORGANIZATIONS', 'INVOICES', organizationId], () => getOrganizationInvoices(organizationId));

export const useAdminOrganizationProducts = (organizationId: string) =>
  useQuery(['ADMIN', 'ORGANIZATIONS', 'PRODUCTS', organizationId], () => getOrganizationProducts(organizationId));
