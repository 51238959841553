import styled, {keyframes} from 'styled-components/macro';

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // Having transparency caused trouble with the sidebar, which also floats over the map, and is our main source of activity "drivers"
  //background-color: rgba(30, 30, 30, 30);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  border: 24px solid ${(props) => props.theme.Colors.Transparents._400};
  border-top: 24px solid ${(props) => props.theme.Colors.Oranges._000};
  border-radius: 50%;
  width: 90px;
  height: 90px;
  flex: 0 0 90px;
  margin-left: -45px;
  margin-top: -45px;
  animation: ${spin} 1s linear infinite;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 100;
`;

const loadingEllipsis = keyframes`
  to {
    width: 1.25em;
  }
`;

export const EllipsisLoader = styled.div`
  font-size: 30px;
  top: -5px;
  width: 30px;
  color: ${(props) => props.theme.Colors.Oranges._000};

  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${loadingEllipsis} steps(4, end) 900ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }
`;

export const FullPageLoader = () => (
  <LoadingOverlay>
    <Loader />
  </LoadingOverlay>
);
