import {FC, useCallback, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {IOrganization, useGetMyOrganization, useUpdateMyOrganization} from '../../API';
import {FlexRow, FullPageLoader} from '../../Components';
import {OrganizationHeader} from '../../Components/Molecules/OrganizationHeader';
import {routePaths} from '../../constants/routePaths';
import {AdminPage, AdminPageWrapper} from '../Admin/Layout';
import {Header} from '../Map/Header';
import {OnboardingPanel} from '../Onboarding/Layout';
import {OrganizationForm} from '../Onboarding/OrganizationForm';
import {AdminMenu} from './AdminMenu';
import {OrgChangeModal} from './OrgChangeModal';

export const MyOrganization: FC = () => {
  const navigate = useNavigate();

  const {data: organization, isLoading} = useGetMyOrganization();
  const updateMyOrganization = useUpdateMyOrganization();
  const [showModal, setShowModal] = useState(false);

  const handleUpdateOrg = useCallback(
    async (values: any) => {
      if (organization?.subscription_type !== values?.subscription_type) {
        setShowModal(true);
      } else {
        try {
          const result = await updateMyOrganization.mutateAsync(values);
          if (!result.error) {
            toast.success('Organization has been successfully updated!');
          }
        } catch (error) {
          console.log('save error', error);
        }
      }
    },
    [organization?.subscription_type, updateMyOrganization],
  );

  return (
    <AdminPageWrapper>
      {(isLoading || updateMyOrganization?.isLoading) && <FullPageLoader />}
      <AdminMenu />

      <Header />

      <AdminPage style={{maxWidth: '100%', overflowY: 'auto'}}>
        <OrganizationHeader organization={organization as IOrganization} title="My Organization" />

        <FlexRow style={{gap: 28}}>
          <OnboardingPanel style={{flex: 1}}>
            <OrganizationForm organization={organization} handleSaveOrg={handleUpdateOrg} submitType="save" />
          </OnboardingPanel>
        </FlexRow>
      </AdminPage>

      <OrgChangeModal isOpen={showModal} onClick1={() => setShowModal(false)} onClick2={() => navigate(routePaths.orgAdminSubscription)} />
    </AdminPageWrapper>
  );
};
