import {useAuth0} from '@auth0/auth0-react';
import {FC, useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {setAccessToken} from '../API';
import {useResendEmailVerification} from '../API/Mutations/useResendEmailVerification';
import {useMyProfile} from '../API/Queries/useMyProfile';
import {FlexFill, FlexRow, StandardButton} from '../Components';
import {AuthenticatedComponent, AuthenticatedOrgAdmin} from '../Components/AuthenticationGuards';
import {useMixpanel} from '../Managers/Mixpanel/useMixpanel';
import {routePaths} from '../constants/routePaths';
import {getConfig} from '../getConfig';
import {LoginRedirect} from '../pages/Login';
import {resetPersist} from '../store';
import * as AdminViews from './Admin';
import {PricingSettings} from './Admin/SubscriptionPricing';
import * as MapViews from './Map';
import {Organization501c3Form} from './Onboarding/501c3Form';
import {CardContent, OnboardingPage, OnboardingPageWrapper, OnboardingPanel, Text} from './Onboarding/Layout';
import {MessageCard} from './Onboarding/MessageCard';
import {ONBOARDING_MESSAGES} from './Onboarding/ONBOARDING_MESSAGES';
import {OrganizationDefaultPosition} from './Onboarding/OrganizationPositions';
import {SetupPayment} from './Onboarding/Payment/SetupPayment';
import {PaymentConfirmed} from './Onboarding/PaymentConfirmed';
import {Root} from './Onboarding/Root';
import {OrganizationLocations} from './Onboarding/SetupLocations';
import {OrganizationMembers} from './Onboarding/SetupMembers';
import {SetupOrganization} from './Onboarding/SetupOrganization';
import {useOnboardingRoute} from './Onboarding/useOnboardingRoute';
import {MyLocations} from './OrgAdmin/MyLocations';
import {MyMembers} from './OrgAdmin/MyMembers';
import {MyOrganization} from './OrgAdmin/MyOrganization';
import {MySubscription} from './OrgAdmin/MySubscription';
// import {AuthenticatedComponent, AuthenticatedOrgAdmin} from '../Components/AuthenticationGuards';

// First, check if authenticated.
// Then get access token.
// Then get profile.
// Then set profile in redux.
// Then set mixpanel user.
// If not authenticated, redirect to map

export const MainRouter: FC = () => {
  const [accessTokenLoaded, setAccessTokenLoaded] = useState(false);
  const {isLoading, isAuthenticated, logout, user, getAccessTokenSilently} = useAuth0();
  const {data: myProfile, isLoading: isLoadingProfile, isError} = useMyProfile(accessTokenLoaded);
  const resendVerificationEmail = useResendEmailVerification();
  const {hasActiveSubscription, hasExistingDomain, isLoading: isLoadingOnboardingRoute} = useOnboardingRoute();

  const config = getConfig();
  const roles = user?.[config.auth0RolesNamespace] || [];

  const {mixpanel} = useMixpanel();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      handleAccessToken();
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (myProfile && !isLoadingProfile && !isError && user) {
      mixpanel.identify(user?.id);

      mixpanel.people.set({
        $email: user?.email,
        USER_ID: myProfile?.user?.id,
        environment: config.env,
        organization_type: myProfile?.user?.organization?.subscription_type,
        roles: roles?.join(','),
      });
    }
  }, [isLoadingProfile]);

  const handleResendVerificationEmail = async () => {
    try {
      await resendVerificationEmail.mutateAsync();
    } catch (e) {
      console.error('Error resending verification email', e);
    }
  };

  const handleAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      setAccessTokenLoaded(true);
    } catch (e) {
      console.error('Error getting Access Token', e);
    }
  };

  const handleLogout = () => {
    setAccessToken(null);
    logout({returnTo: window.location.origin + '/'});
    resetPersist();
  };

  if (
    (isLoading && !isAuthenticated) ||
    (!isLoading && isAuthenticated && !myProfile && !accessTokenLoaded) ||
    isLoadingProfile ||
    isLoadingOnboardingRoute
  ) {
    return <MessageCard message={ONBOARDING_MESSAGES.loading} isLoading />;
  }

  if (!hasActiveSubscription && hasExistingDomain) {
    return <MessageCard message={ONBOARDING_MESSAGES.emailDomainAlreadyExists} onClick={handleLogout} />;
  }

  if (!isLoading && isAuthenticated && !user?.email_verified) {
    return (
      <OnboardingPageWrapper>
        <OnboardingPage>
          <OnboardingPanel style={{gap: 16, alignItems: 'center'}}>
            <CardContent style={{width: 500}}>
              <Text>
                An email verification link was sent to your email address. Please check your inbox and verify your account to continue.
              </Text>
              <FlexRow style={{gap: 16, alignItems: 'center'}}>
                {!resendVerificationEmail.isSuccess && (
                  <StandardButton style={{flex: 1, padding: '0 20px'}} onClick={handleResendVerificationEmail}>
                    Resend Verification Email
                  </StandardButton>
                )}
                <StandardButton outline style={{flex: 1, padding: '0 20px'}} onClick={() => window.open('/')}>
                  I've Confirmed My Account, Try Again
                </StandardButton>
              </FlexRow>
            </CardContent>
          </OnboardingPanel>
        </OnboardingPage>
        <FlexFill style={{flex: 0}} />
      </OnboardingPageWrapper>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<Navigate replace to={routePaths.onBoardingConfirm} />} />
      <Route path={routePaths.login} element={<LoginRedirect />} />
      <Route path={routePaths.map} element={<MapViews.Map />} />
      <Route path={routePaths.admin} element={<Navigate replace to={routePaths.adminOrganizations} />} />
      <Route path={routePaths.adminOrganizations} element={<AdminViews.Organizations />} />
      <Route path={routePaths.adminOrganizationCreate} element={<AdminViews.CreateOrganization />} />
      <Route path={routePaths.adminOrganizationsById} element={<AdminViews.EditOrganization />} />
      <Route path={routePaths.adminProductCreate} element={<AdminViews.CreateProduct />} />
      <Route path={routePaths.adminProductsById} element={<AdminViews.EditProduct />} />
      <Route path={routePaths.adminProducts} element={<AdminViews.Products />} />
      <Route path={routePaths.adminUsers} element={<AdminViews.Users />} />
      <Route path={routePaths.onBoardingConfirm} element={<AuthenticatedComponent component={Root} />} />
      <Route path={routePaths.onboardingLocal} element={<AuthenticatedComponent component={Organization501c3Form} />} />
      <Route path={routePaths.onboardingLocations} element={<AuthenticatedComponent component={OrganizationLocations} />} />
      <Route path={routePaths.onboardingDefaultLocation} element={<AuthenticatedComponent component={OrganizationDefaultPosition} />} />
      <Route path={routePaths.onboardingMembers} element={<AuthenticatedComponent component={OrganizationMembers} />} />
      <Route path={routePaths.onboardingOrganization} element={<AuthenticatedComponent component={SetupOrganization} />} />
      <Route path={routePaths.onboardingPayment} element={<AuthenticatedComponent component={SetupPayment} />} />
      <Route path={routePaths.onboardingPaymentConfirmed} element={<AuthenticatedComponent component={PaymentConfirmed} />} />
      <Route path={routePaths.orgAdminOrganization} element={<AuthenticatedOrgAdmin component={MyOrganization} />} />
      <Route path={routePaths.orgAdminMembers} element={<AuthenticatedOrgAdmin component={MyMembers} />} />
      <Route path={routePaths.orgAdminLocations} element={<AuthenticatedOrgAdmin component={MyLocations} />} />
      <Route path={routePaths.orgAdminSubscription} element={<AuthenticatedOrgAdmin component={MySubscription} />} />
      <Route path={routePaths.settings} element={<PricingSettings />} />
    </Routes>
  );
};
