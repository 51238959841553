import {useMutation, useQueryClient} from 'react-query';
import {updateMyOrganization} from '../Calls/OrgAdmin';

export const useUpdateMyOrganization = () => {
  const qc = useQueryClient();

  return useMutation(updateMyOrganization, {
    onSuccess: (organization) => {
      console.log('Updated my organization', organization);
    },

    onError: (err: Error, _, context) => {
      console.log('Error updating my organization', err.message);
    },

    onSettled: () => {
      qc.invalidateQueries(['ONBOARDING', 'ORGANIZATION']);
      qc.invalidateQueries(['PROFILE']).catch(() => {});
    },
  });
};
