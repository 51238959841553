import {FC} from 'react';
import {IOrganization} from '../../../API';
import {OrganizationLocationsPanels} from '../../Onboarding/OrganizationLocationsPanels';
import {AdminPage} from '../Layout';

export const LocationsTab: FC<{organization: IOrganization}> = ({organization}) => {
  return (
    <AdminPage style={{maxWidth: '100%'}}>
      <div style={{display: 'flex', flexDirection: 'row', gap: 24, maxWidth: '100%'}}>
        <OrganizationLocationsPanels organization={organization as IOrganization} hideTitle updateSubscriptionOnly />
      </div>
    </AdminPage>
  );
};
