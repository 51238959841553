import {useMutation, useQueryClient} from 'react-query';
import {updateMySubscription} from '../Calls/OrgAdmin';

export const useUpdateMySubscription = () => {
  const qc = useQueryClient();

  return useMutation(updateMySubscription, {
    onSuccess: (organization) => {
      console.log('Updated my subscription', organization);
      qc.invalidateQueries(['ONBOARDING', 'ORGANIZATION']).catch(() => {});
    },

    onError: (err: Error, _, context) => {
      console.log('Error updating my subscription', err.message);
    },

    onSettled: () => {
      qc.invalidateQueries(['ONBOARDING', 'SUBSCRIPTION']);
    },
  });
};
