import {useAuth0} from '@auth0/auth0-react';
import {Formik, FormikValues} from 'formik';
import {FC, useEffect, useRef, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {IOrganization, IUser, useUpdateMySubscription} from '../../API';
import {useOnboardingSteps} from '../../Hooks/useOnboardingSteps';
import {OnboardingPanel} from './Layout';
import {SubscriptionDetailsPanel} from './OnboardingSubscriptionDetails';
import {OrganizationMembersForm} from './OrganizationMembersForm';
import {publicEmailList} from './emailBlacklist';
import {useMixpanel} from '../../Managers/Mixpanel/useMixpanel';

const organizationMemberSchema = ({organization}: {organization: IOrganization}) =>
  yup.object({
    users: yup
      .array()
      .of(
        yup.object({
          email: yup.string().email('Please enter a valid email address').trim().required('Please enter member email.'),
          user_type: yup.string().trim().required('Please select member role.'),
        }),
      )
      .test('unique', 'Only unique email addresses allowed', (value) => {
        const seen = new Set();
        return (
          Array.isArray(value) &&
          value.every((item) => {
            if (seen.has(item.email)) {
              return false;
            }
            seen.add(item.email);
            return true;
          })
        );
      })

      .test('blacklist', 'Public email address is not allowed', (value) => {
        if (!organization.force_private_domain) {
          return true;
        }

        return (
          Array.isArray(value) &&
          value.every((item) => {
            if (!item.email) {
              return true;
            }

            const domain = item?.email?.split('@')[1];

            if (item.email && publicEmailList.includes(domain)) {
              return false;
            }

            return true;
          })
        );
      }),
  });

export const OrganizationMembersPanels: FC<{
  organization: IOrganization;
  hideTitle?: boolean;
  hideLocations?: boolean;
  readOnly?: boolean;
  onSubmit?: (values: any) => Promise<any>;
  updateSubscriptionOnly?: boolean;
  isForTrial?: boolean;
}> = ({organization, hideTitle, hideLocations, onSubmit, readOnly, updateSubscriptionOnly, isForTrial}) => {
  const updateMySubscription = useUpdateMySubscription();
  const navigate = useNavigate();
  const {user} = useAuth0();
  const [users, setUsers] = useState<IUser[]>([]);
  const formRef = useRef<FormikValues>();
  const {currentStep, stepMap} = useOnboardingSteps();
  const {mixpanel} = useMixpanel();

  // Staff should not be org admins
  const you = users?.find((u: any) => u.email === user?.email);

  useEffect(() => {
    setUsers(organization?.users || []);
  }, [organization?.users]);

  const handleUsersChange = (values: any) => {
    setUsers(values?.users);
  };

  const handleSubmit = async (values: any, actions: any) => {
    const usersToUpdate = values?.users?.map((user: IUser) => ({
      email: user.email,
      user_type: user.user_type,
    }));

    try {
      if (onSubmit) {
        await onSubmit({...organization, users: usersToUpdate});
      }

      if (isForTrial) {
        return;
      }

      if (updateSubscriptionOnly) {
        await updateMySubscription.mutateAsync({...organization, users: usersToUpdate});
        toast.success('Your subscription has been updated.');
      } else {
        toast.success('Your members have been added.');
        mixpanel.track('Onboarding Members Added');

        navigate(stepMap[currentStep + 1]);
      }
    } catch (error) {
      console.log('Error saving organization', error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleUpdateSubscriptionSubmit = async () => {
    if (formRef.current) {
      await formRef.current.handleSubmit();
    }
  };

  const formSchema = organizationMemberSchema({
    organization: organization as IOrganization,
  });

  return (
    <Formik
      // @ts-ignore
      innerRef={formRef}
      initialValues={organization || ({} as IOrganization)}
      onSubmit={(values, actions) => {
        handleSubmit(formSchema.cast(values), actions);
      }}
      validationSchema={formSchema}
      enableReinitialize>
      {(props) => (
        <>
          <OnboardingPanel style={{flex: 3}}>
            <div>
              <OrganizationMembersForm
                formikProps={props}
                hideTitle={hideTitle}
                onChange={handleUsersChange}
                readOnly={readOnly}
                you={you}
              />
            </div>
          </OnboardingPanel>
          <SubscriptionDetailsPanel
            authorizations={organization?.authorizations || []}
            formikProps={props}
            hideLocations={hideLocations}
            onClickSubmit={handleUpdateSubscriptionSubmit}
            organization={organization}
            hideActionButtons={readOnly}
            // title={organization?.name}
            showUpdateButton={updateSubscriptionOnly || isForTrial}
            skipDirtyCheck={!updateSubscriptionOnly && !isForTrial}
            users={users || []}
          />
        </>
      )}
    </Formik>
  );
};
