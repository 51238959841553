import {useAuth0} from '@auth0/auth0-react';
import {Form, Formik} from 'formik';
import {FC, useState} from 'react';
import ReactJoyride from 'react-joyride';
import {useNavigate} from 'react-router-dom';
import {useAsyncDebounce} from 'react-table';
import styled from 'styled-components';
import {
  IOrganization,
  ISubscriptionPricing,
  SubscriptionType,
  TMarketingOptIn,
  setAccessToken,
  useAddToMailchimp,
  useCheckForDuplicateOrg,
  useSubscriptionPricing,
} from '../../API';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {
  AddressSearch,
  BasicCheckbox,
  Body2,
  Callout,
  ErrorBody,
  FieldLabel,
  FieldWrapper,
  FlexRow,
  FormField,
  Modal,
  OrgTypeDescriptionsModal,
  RequiredSpan,
  StandardButton,
  TextButton,
  TextInput,
  Theme,
} from '../../Components';
import {useOnboardingSteps} from '../../Hooks/useOnboardingSteps';
import {useRoles} from '../../Hooks/useRoles';
import {SUBSCRIPTION_IMAGES} from '../../constants/constants';
import {routePaths} from '../../constants/routePaths';
import {CardRadio} from './CardRadio';
import './OrganizationForm.css';
import {Button} from './OrganizationMembersForm';
import {organizationSchema} from './organizationSchema';

interface IOrganizationForm {
  organization?: IOrganization & {marketingOptin?: TMarketingOptIn};
  handleSaveOrg: (values: IOrganization) => any;
  submitType: 'save' | 'next';
  readOnly?: boolean;
  disabled?: boolean;
  enableTypeSelection?: boolean;
}

// const NEWSLETTER_LIST = [
//   {
//     title: 'Greenlink Newsletter',
//     prompt: 'Subscribe to the Greenlink Newsletter',
//     listId: 'IWPHGtjIOSmOzildKtHZHnA7J5ZKDEok',
//     description: 'Keep up with the latest news at Greenlink by subscribing to our monthly newsletter.',
//   },
//   {
//     title: 'Community of Practice',
//     prompt: 'Subscribe to the Community of Practice',
//     listId: '22222222',
//     description:
//       'The Community of Practice (CP) supports community-based practitioners and city staff using the GEM platform who are committed to learning more about how data and mapping can advance climate equity goals. Monthly workshops, network building, and resource sharing help users learn from each other and build productive relationships between frontline communities and city government. Through peer consultancy, leadership, and participation, community of practice members develop skills and troubleshoot challenges creating solutions for racially equitable climate action.',
//   },
// ];

// const newsletterDefaults = NEWSLETTER_LIST.reduce((acc, {listId}) => ({...acc, [listId]: true}), {});

const LocalButton = styled(TextButton)`
  color: ${Theme.Colors.Oranges._000};
  text-decoration: underline;
  padding-bottom: 20px;
  text-wrap: nowrap;

  &:hover {
    color: ${Theme.Colors.Oranges._300};
  }
`;

const joyrideSteps = [
  {
    target: '#org-cards',
    content: 'Select your organization type',
  },
];

const joyrideOptions = {
  options: {
    arrowColor: Theme.Colors.Oranges._000,
    backgroundColor: Theme.Colors.Neutrals._500,
    overlayColor: Theme.Colors.Neutrals._200,
    primaryColor: Theme.Colors.Oranges._000,
    textColor: Theme.Colors.Neutrals._100,
    zIndex: 1000,
  },
  overlay: {
    // Overriding the overlay defaults
    backgroundColor: Theme.Colors.Neutrals._200,
    zIndex: 1000,
  },
};

export const OrganizationForm: FC<IOrganizationForm> = ({
  organization,
  handleSaveOrg,
  submitType,
  readOnly,
  disabled,
  enableTypeSelection,
}) => {
  const [typeInfoIsOpen, setTypeInfoIsOpen] = useState(false);
  const [name, setName] = useState('');

  const {data: myProfile, isFetching: isFetchingProfile} = useMyProfile();
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const {data: nameCheckResult} = useCheckForDuplicateOrg(name);
  const addToMailchimpList = useAddToMailchimp();

  const navigate = useNavigate();
  const {logout} = useAuth0();
  const {isAdmin} = useRoles();
  const {currentStep, stepMap} = useOnboardingSteps();

  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;

  const subscriptionType = organization?.subscription_type ?? ('standard' as SubscriptionType);

  // Get them in the order we want them to display
  const typesForForm = enableTypeSelection
    ? {
        standard: subscriptionTypes?.standard,
        discounted: subscriptionTypes?.discounted,
        // local: subscriptionTypes?.local,
        // ...(enableTypeSelection && {local: subscriptionTypes?.local}),
      }
    : ({
        [subscriptionType]: subscriptionTypes?.[subscriptionType || 'standard'],
      } as ISubscriptionPricing['subscriptionTypes']);

  const handleNewsLetterSignup = async ({marketingOptIn}: {marketingOptIn: TMarketingOptIn}) => {
    for (const listId in marketingOptIn) {
      if (marketingOptIn[listId]) {
        try {
          const response = await addToMailchimpList.mutateAsync({
            listId,
            firstName: myProfile?.user?.firstName ?? myProfile?.user?.orgName ?? '',
            lastName: myProfile?.user?.lastName ?? '',
          });

          return response;
        } catch (error) {
          console.log('newsletter signup error', error);
        }
      }
    }
  };

  const handleSubmit = async (values: any, actions: any) => {
    // formvalues have changed
    if (
      values.subscription_type !== subscriptionType ||
      values.name !== organization?.name ||
      values.primary_email !== organization?.primary_email ||
      values.address1 !== organization?.address1 ||
      values.address2 !== organization?.address2 ||
      values.city !== organization?.city ||
      values.state !== organization?.state ||
      values.zip !== organization?.zip ||
      values.force_private_domain !== organization?.force_private_domain
    ) {
      try {
        if (values.marketingOptin) {
          await handleNewsLetterSignup({
            marketingOptIn: values.marketingOptin,
          });
        }

        await handleSaveOrg(values);
      } catch (error) {
        console.log('Error saving organization', error);
      } finally {
        actions.setSubmitting(false);
      }
    } else {
      if (submitType !== 'save') {
        handleNext();
      }
    }
  };

  // debounce check for duplicate
  const handleNameChange = useAsyncDebounce((value) => {
    setName(value || undefined);
  }, 400);

  const handleSelectLocal = async (values: IOrganization) => {
    values.subscription_type = subscriptionTypes?.local?.value as SubscriptionType;
    await handleSaveOrg(values);
  };

  const handleClickType = (value: string, setFieldValue: any) => {
    setFieldValue('subscription_type', value);
  };

  const formSchema = organizationSchema({
    nameFound: nameCheckResult?.found,
    isAdmin,
  });

  const handleCancel = () => {
    if (organization?.id && myProfile?.user?.user_status === 'active') {
      navigate(routePaths.map);
    } else {
      setAccessToken(null);
      logout({returnTo: window.location.origin + '/'});
    }
  };

  const handleNext = () => {
    navigate(stepMap[currentStep + 1]);
  };

  const showDomainMatchOption = isAdmin && organization?.id;

  return (
    <>
      <Formik
        initialValues={{
          ...organization,
          subscription_type: subscriptionType,
          // ...(!organization?.id ? {marketingOptin: myProfile?.user?.marketingOptIn ?? newsletterDefaults} : newsletterDefaults),
        }}
        onSubmit={(values, actions) => {
          handleSubmit(formSchema.cast(values) as IOrganization, actions);
        }}
        validationSchema={formSchema}
        enableReinitialize>
        {({dirty, setFieldValue, isSubmitting, errors, values, initialValues, status, touched, submitCount, handleBlur}) => {
          const handleSelectAddress = (value: any) => {
            const zip = value?.results?.[0]?.address_components?.find((c: any) => c.types.includes('postal_code'))?.long_name;

            setFieldValue('address1', value?.entry?.structured_formatting?.main_text || value);

            if (value?.entry?.structured_formatting?.secondary_text) {
              setFieldValue('city', value?.entry?.structured_formatting?.secondary_text?.split(',')[0]);
              setFieldValue('state', value?.entry?.structured_formatting?.secondary_text?.split(',')[1]?.trim().split(' ')[0]);
            }

            if (zip) {
              setFieldValue('zip', zip);
            }
          };
          return (
            <Form>
              <FlexRow>
                {!!status && (
                  <Callout style={{textAlign: 'center'}}>
                    {subscriptionTypes?.local?.label}
                    {status}
                  </Callout>
                )}
              </FlexRow>

              <FlexRow style={{gap: 30}}>
                <FormField style={{flex: 1}}>
                  <TextInput
                    type="text"
                    name="name"
                    required
                    placeholder="Enter name"
                    label="Org name"
                    onChange={(e) => handleNameChange(e.target.value)}
                    disabled={disabled}
                  />
                </FormField>

                <FormField style={{flex: 1}}>
                  <FieldWrapper>
                    <FieldLabel>
                      Address
                      <RequiredSpan> *</RequiredSpan>
                    </FieldLabel>

                    <AddressSearch
                      name="address1"
                      placeholder="Enter an Address "
                      onSelectAddress={handleSelectAddress}
                      value={values.address1 || ''}
                      onBlur={handleBlur}
                      disabled={disabled}
                    />

                    {errors.address1 && (touched.address1 || submitCount > 0) && <ErrorBody>{errors.address1}</ErrorBody>}
                  </FieldWrapper>
                </FormField>

                <FormField style={{flex: 1}}>
                  <TextInput
                    type="text"
                    name="address2"
                    placeholder="Enter unit"
                    label="Unit"
                    onChange={(e) => handleNameChange(e.target.value)}
                    disabled={disabled}
                  />
                </FormField>
              </FlexRow>

              <FlexRow style={{gap: 30}}>
                <FormField style={{flex: 1}}>
                  <TextInput type="text" name="city" placeholder="Enter city" label="City" required disabled={disabled} />
                </FormField>

                <FormField style={{flex: 1}}>
                  <TextInput type="text" name="state" placeholder="Enter state" label="State" required disabled={disabled} />
                </FormField>

                <FormField style={{flex: 1}}>
                  <TextInput type="text" name="zip" placeholder="Enter zip" label="Zip" required disabled={disabled} />
                </FormField>
              </FlexRow>

              {!enableTypeSelection && (
                <FlexRow style={{gap: 30}}>
                  <FormField style={{flex: 1}}>
                    <TextInput
                      type="email"
                      name="primary_email"
                      placeholder="Enter primary email"
                      label="Primary email"
                      required
                      disabled={disabled}
                    />
                  </FormField>
                </FlexRow>
              )}

              {showDomainMatchOption && (
                <FormField style={{flex: 1}}>
                  <BasicCheckbox name="force_private_domain" label="Prevent using public email domains" wrap />
                </FormField>
              )}

              <FlexRow style={{marginBottom: 18}}>
                <FormField style={{flex: 1}}>
                  <FieldWrapper>
                    <FieldLabel>
                      Organization Type
                      <RequiredSpan> *</RequiredSpan>
                    </FieldLabel>

                    <ReactJoyride steps={joyrideSteps} debug showProgress styles={joyrideOptions} run={false} />

                    {enableTypeSelection && (
                      <FlexRow style={{margin: 0, padding: 0, gap: 8, alignItems: 'flex-end', justifyContent: 'flex-start'}} id="org-cards">
                        <Body2>Not sure what to pick?</Body2>

                        <LocalButton style={{padding: 0}} type="button" onClick={() => setTypeInfoIsOpen(true)}>
                          Click here for more information.
                        </LocalButton>
                      </FlexRow>
                    )}

                    <FlexRow style={{gap: 16}} id="org-cards">
                      {!!subscriptionTypes &&
                        Object.values(typesForForm).map((type) => (
                          <CardRadio
                            key={type?.value}
                            id={type?.value}
                            currentValue={submitType === 'save' ? initialValues?.subscription_type ?? '' : ''}
                            value={type?.value}
                            name="subscription_type"
                            title={type?.label}
                            disabled={readOnly || disabled || !enableTypeSelection}
                            onClick={(value) => handleClickType(value, setFieldValue)}
                            subtitle={type?.subtitle}>
                            <img alt={type?.value} width="120" height="88" src={SUBSCRIPTION_IMAGES[type?.value as SubscriptionType]}></img>
                          </CardRadio>
                        ))}
                    </FlexRow>
                  </FieldWrapper>
                </FormField>
              </FlexRow>

              {enableTypeSelection && !isAdmin && (
                <FlexRow style={{marginBottom: 18, justifyContent: 'center'}}>
                  <LocalButton
                    type="button"
                    onClick={() => handleSelectLocal(values as IOrganization)}
                    style={{whiteSpace: 'nowrap', margin: '0', padding: '0 16px'}}>
                    Is your company a 501(c)(3) or small independent organization?
                  </LocalButton>
                </FlexRow>
              )}

              {submitType !== 'save' && <MarketingSection />}

              {submitType === 'save' ? (
                <FlexRow style={{justifyContent: 'center', marginBottom: 18}}>
                  <StandardButton
                    type="submit"
                    style={{flex: 1, maxWidth: '200px'}}
                    disabled={isFetchingProfile || isSubmitting || status || !dirty}>
                    Save
                  </StandardButton>
                </FlexRow>
              ) : (
                <FlexRow style={{justifyContent: 'center', marginBottom: 18}}>
                  <StandardButton type="button" style={{flex: 1, marginRight: 30}} outline onClick={handleCancel}>
                    Cancel
                  </StandardButton>

                  <StandardButton
                    type="submit"
                    style={{flex: 1}}
                    disabled={isFetchingProfile || isSubmitting || status || values.subscription_type === subscriptionTypes?.local.value}>
                    Next
                  </StandardButton>
                </FlexRow>
              )}
            </Form>
          );
        }}
      </Formik>

      <OrgTypeDescriptionsModal isOpen={typeInfoIsOpen} onClose={() => setTypeInfoIsOpen(false)} />
    </>
  );
};

const MarketingSection = () => {
  const [showMarketingOptIn, setShowMarketingOptIn] = useState(false);

  const toggleMarketingOptIn = () => setShowMarketingOptIn(!showMarketingOptIn);

  return (
    <div style={{gap: 8, marginBottom: 20}}>
      <Button onClick={toggleMarketingOptIn} outline type="button">
        Subscribe to our newsletter
      </Button>

      <Modal button1Action={toggleMarketingOptIn} isOpen={showMarketingOptIn} button1Text="Close" width={800}>
        <NewsLetterIFrame />
      </Modal>
    </div>
  );
};

const NewsLetterIFrame = () => {
  return (
    <iframe
      id="JotFormIFrame-241685999028171"
      title="Greenlink Email Signups"
      onLoad={() => window.parent.scrollTo(0, 0)}
      allowTransparency={true}
      allow="geolocation; microphone; camera; fullscreen"
      src="https://form.jotform.com/241685999028171"
      style={{minWidth: '100%', maxWidth: '100%', height: 539, border: 'none'}}></iframe>
  );
};
