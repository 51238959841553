import {FC} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {useAdminOrganization, useGetMyOrganization, useSubscriptionPricing} from '../../API';
import {Body2, BoldSubhead1, FlexRow} from '../../Components';
import {OnboardingPanel} from '../Onboarding/Layout';

const BoldSpan = styled.span`
  font-weight: bold;
  word-break: break-word;
`;

const SummaryDetail = ({label, value}: {label: string; value?: any}) => {
  return (
    <Body2 style={{marginBottom: 4}}>
      <span style={{}}>{label}</span>: <BoldSpan>{value || '-'}</BoldSpan>
    </Body2>
  );
};

export const OrganizationSummary: FC = () => {
  // get organization id from url
  const params = useParams();
  const organizationId = params.organizationId;
  const {data: organizationData} = useAdminOrganization(organizationId || '');
  const {data: myOrganization} = useGetMyOrganization();
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;

  const organization = organizationData || myOrganization;

  const subscriptionType = organization?.subscription_type;

  const subscriptionStatus = organization?.subscription?.status;
  const currentStartDate =
    organization?.subscription?.current_period_start && new Date(organization?.subscription?.current_period_start).toDateString();
  const currentEndDate =
    organization?.subscription?.current_period_end && new Date(organization?.subscription?.current_period_end).toDateString();

  const orgUsers =
    organization?.users?.filter((u) => u.user_type === 'org-admin' && u.firstName)?.map((u) => `${u.firstName} ${u.lastName}`) || [];

  const usersWithSubscriptions = orgUsers.length ? orgUsers?.join(', ') : 'none';

  return (
    <OnboardingPanel style={{width: 276, minWidth: 276, gap: 8}}>
      <BoldSubhead1>{organization?.name || 'Subscription'}</BoldSubhead1>

      {!!subscriptionType && <Body2 style={{wordBreak: 'break-word', margin: 0}}>{subscriptionTypes?.[subscriptionType]?.label}</Body2>}
      {!!organization?.primary_email && <Body2 style={{margin: 0, wordBreak: 'break-word'}}>{organization?.primary_email}</Body2>}

      <FlexRow style={{flex: 0, alignItems: 'flex-start', justifyContent: 'space-between'}}>
        {!!organization ? (
          <div style={{flex: 1, gap: 8}}>
            <SummaryDetail label={orgUsers.length === 1 ? 'Admin' : 'Admins'} value={usersWithSubscriptions} />

            <SummaryDetail label="Subscription status" value={subscriptionStatus} />

            <SummaryDetail label="Period start" value={currentStartDate} />

            <SummaryDetail label="Period end" value={currentEndDate} />
          </div>
        ) : (
          <>Select an organization</>
        )}
      </FlexRow>
    </OnboardingPanel>
  );
};
